<template>
  <drawer-layout
    ref="drawerRef"
    :title="setTitle"
    :show="open"
    :isForm="isForm"
    @show="show"
    @closed="closed"
    @save="save"
    @edit="edit"
    permission="statistics:review:edit"
  >
    <!--     <template slot="other-region">
      <a-steps
        :current="formData.status - 1"
        size="small"
        class="mb-6"
        :status="formData.rejectType != 0 ? 'error' : 'process'"
      >
        <a-step title="HR确认" />
        <a-step title="BOSS确认" />
      </a-steps>
    </template> -->

    <!--     <template slot="other-buttons">
      <a-button type="primary" class="mr-3" @click="showTaskList">评分明细</a-button>
    </template> -->
    <!-- 详情 -->
    <div class="">
      <info-row label="平台名称">{{ formData.platformName }}</info-row>
      <info-row label="作品序号">{{ formData.productOrder }}</info-row>
      <info-row label="作品名称">{{ formData.productName }}</info-row>
      <info-row label="系数分类">{{
        formData.type == 'TRANSLATOR' ? '翻译' : formData.type == 'BUILDER' ? '制作' : ''
      }}</info-row>
      <info-row label="难度系数">{{ formData.value }}</info-row>
      <info-row label="是否评审">{{ formData.autoGenerate ? '否' : '是' }}</info-row>
      <info-row label="评审人员">{{ formData.creatorName }}</info-row>
      <!-- <info-row label="评审月份">{{ formData.date }}</info-row> -->
      <info-row label="状态">{{ this.getStatusText(formData) }}</info-row>
      <!--       <info-row label="备注" wrapCol="19">
        <a-textarea autoSize v-model="formData.remark"></a-textarea>
      </info-row> -->
      <info-row label="历史记录">
        <a-timeline style="margin-top:6px;width: 140%;">
          <a-timeline-item style="font-size:14px" v-for="(item, index) in logList" :key="index"
            >({{ item.gmtCreate }}){{ ' ' }} {{ item.message }}</a-timeline-item
          >
        </a-timeline>
      </info-row>
      <div style="text-align: right" v-if="formData.status != 4">
        <a-button type="danger" class="mr-3" v-has="'statistics:review:reject'" @click="rejectRating">驳回</a-button>
        <a-button type="primary" v-has="'statistics:review:confirm'" @click="confirmRating">确认</a-button>
      </div>
    </div>
    <!-- 表单 -->
    <div slot="form">
      <a-form-model ref="dataForm" layout="horizontal" :model="formData" :rules="rules" v-bind="layout">
        <a-form-model-item label="平台名称">
          <a-input v-model="formData.platformName" placeholder="请输入平台名称" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="作品序号">
          <a-input v-model="formData.productOrder" placeholder="请输入作品序号" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="作品名称">
          <a-input v-model="formData.productName" placeholder="请输入作品名称" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="系数分类">
          <a-select v-model="formData.type" :disabled="true">
            <a-select-option value="TRANSLATOR">翻译</a-select-option>
            <a-select-option value="BUILDER">制作</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="难度系数">
          <a-input v-model="formData.value" placeholder="请输入难度系数"></a-input>
        </a-form-model-item>
        <a-form-model-item label="评审人员">
          <a-input :disabled="true" v-model="formData.creatorName" placeholder="请输入评审人员"></a-input>
        </a-form-model-item>
        <!-- <a-form-model-item label="评审月份">
          <a-input v-model="formData.date" :disabled="true" placeholder="请输入评审月份"></a-input>
        </a-form-model-item> -->
        <a-form-model-item label="状态">
          <a-select v-model="formData.status" :disabled="true">
            <a-select-option value="REVIEW_FINISH">BOSS确认</a-select-option>
            <a-select-option value="REVIEW_CONFIRM">HR确认</a-select-option>
            <a-select-option value="REVIEW_REJECT">BOSS驳回</a-select-option>
            <a-select-option value="REVIEW_SUBMIT">人员确认</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div v-if="showScoreDetail">
      <g-title>评分明细</g-title>
      <a-table
        ref="table"
        size="middle"
        rowKey="id"
        :columns="columns"
        :dataSource="taskList"
        :pagination="false"
        :loading="loading"
        :scroll="{ x: 800, y: 500 }"
      >
      </a-table>
    </div>
  </drawer-layout>
</template>

<script>
import { DrawerMixins } from '@/mixins/DrawerMixins'
import { getAction, postAction,putAction } from '@/api/manage'
export default {
  components: {},
  mixins: [DrawerMixins],

  data() {
    return {
      rules: {
        productionCount: [{ required: true, message: '请输入作品数量', trigger: 'blur' }],
        taskCount: [{ required: true, message: '请输入任务数量', trigger: 'blur' }],
        averageScore: [{ required: true, message: '请输入平均分', trigger: 'blur' }],
        level: [{ required: true, message: '请输入等级', trigger: 'blur' }]
      },
      showScoreDetail: false,
      url: {
        edit: '/difficultyFactor'
      },
      taskList: [],
      loading: false,
      oldFormData: {},
      logList: [],
      columns: [
        {
          title: '章节序号',
          dataIndex: 'chapterSequenceNo',
          width: 100
        },
        {
          title: '数量',
          dataIndex: 'pageCount',
          width: 100
        },
        {
          title: '人员',
          dataIndex: 'user.userName',
          width: 100
        },
        {
          title: '截止日期',
          dataIndex: 'deadline',
          width: 120
        },
        {
          title: '结算日期',
          dataIndex: 'settlementDate',
          width: 120
        },
        {
          title: '考核结果',
          dataIndex: 'evalResult',
          width: 200
        },
        {
          title: '考核得分',
          dataIndex: 'evalScore'
        }
      ]
    }
  },
  methods: {
    getStatusText(record) {
      switch (record.status) {
        case 'REVIEW_FINISH':
          return 'BOSS确认'
        case 'REVIEW_CONFIRM':
          return 'HR确认'
        case 'REVIEW_REJECT':
          return 'BOSS驳回'
        case 'REVIEW_SUBMIT':
          return '人员确认'
      }
    },
    initForm() {
      this.formData = {
        comment: ''
      }
    },
    save() {
      const self = this
      this.setFormData()
      console.log('保存...')
      this.$refs['dataForm'].validate(valid => {
        if (valid) {
          if (self.isEdit || self.isEditForm) {
            self.formData.platformId = self.curRow.platformId
            let arr = [self.formData]
            postAction(self.url.edit, arr).then(async res => {
              if (res.success) {
                if (self.isBlack) {
                  const response = await postAction('/qualityInspection/blackWhiteSync')
                  if (response.data.code != 0) {
                    self.$message.error(response.data)
                  }
                }
                self.$message.success(res.message || res.msg ? '保存成功' : '')
                self.isSaved = true
                self.closed()
              } else {
                self.$message.error(res.message || res.msg)
              }
            })
          } else {
            console.log('新建URL', self.url.add)
            console.log('新建参数', self.formData)
            postAction(self.url.add, self.formData).then(async res => {
              if (res.success) {
                if (self.isBlack) {
                  const response = await postAction('/qualityInspection/blackWhiteSync')
                  if (response.data.code != 0) {
                    self.$message.error(response.data)
                  }
                }
                self.$message.success(res.message || res.msg ? '保存成功' : '')
                self.isSaved = true
                self.closed()
                self.closedModel()
              } else {
                self.$message.error(res.message || res.msg)
              }
            })
          }
        }
      })
    },
    async show() {
      this.formData = this.curRow
      if (this.formData.rejectType == 1) {
        this.formData.comment = this.formData.bossComment
      } else if (this.formData.rejectType == 2) {
        this.formData.comment = this.formData.staffComment
      }
      this.isEdit = this.isEditForm
      this.taskList = []
      this.showScoreDetail = false
      this.oldFormData = JSON.parse(JSON.stringify(this.formData))
      this.$refs.drawerRef.showFullScreen(this.showScoreDetail)
      console.log(this.curRow)
      const res = await getAction('/difficultyFactor/histories?factorId=' + this.curRow.id)
      if (res.code == 200) {
        this.logList = res.data
      }
    },
    showTaskList() {
      this.showScoreDetail = !this.showScoreDetail
      this.$refs.drawerRef.showFullScreen(this.showScoreDetail)
      if (this.showScoreDetail && this.taskList.length == 0) {
        this.loading = true
        getAction('/rating/get_tasks?ids=' + this.formData.taskIds).then(({ success, data }) => {
          if (success) {
            this.taskList = data
          }
          this.loading = false
        })
      }
    },
    async confirmRating() {
      let list = [
        {
          operation: 'DOWN',
          version: this.formData.version,
          id: this.formData.id
        }
      ]
      const res = await putAction('/v2/difficultyFactor/status/process/batch', list)
      if (res.success) {
        this.isSaved = true
        this.$message.success('已确认')
        this.closed()
      } else {
        this.$message.error(res.msg)
      }
    },
    async rejectRating() {
      let list = [
        {
          operation: 'UP',
          version: this.formData.version,
          id: this.formData.id
        }
      ]
      const res = await putAction('/v2/difficultyFactor/status/process/batch', list)
      if (res.success) {
        this.isSaved = true
        this.$message.success('驳回成功')
        this.closed()
      } else {
        this.$message.error(res.msg)
      }
    },

    setFormData() {}
  },
  created() {
    this.initForm()
  }
}
</script>

<style lang="less" scoped></style>
