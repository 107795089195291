var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "drawer-layout",
    {
      ref: "drawerRef",
      attrs: {
        title: _vm.setTitle,
        show: _vm.open,
        isForm: _vm.isForm,
        permission: "statistics:review:edit",
      },
      on: {
        show: _vm.show,
        closed: _vm.closed,
        save: _vm.save,
        edit: _vm.edit,
      },
    },
    [
      _c(
        "div",
        {},
        [
          _c("info-row", { attrs: { label: "平台名称" } }, [
            _vm._v(_vm._s(_vm.formData.platformName)),
          ]),
          _c("info-row", { attrs: { label: "作品序号" } }, [
            _vm._v(_vm._s(_vm.formData.productOrder)),
          ]),
          _c("info-row", { attrs: { label: "作品名称" } }, [
            _vm._v(_vm._s(_vm.formData.productName)),
          ]),
          _c("info-row", { attrs: { label: "系数分类" } }, [
            _vm._v(
              _vm._s(
                _vm.formData.type == "TRANSLATOR"
                  ? "翻译"
                  : _vm.formData.type == "BUILDER"
                  ? "制作"
                  : ""
              )
            ),
          ]),
          _c("info-row", { attrs: { label: "难度系数" } }, [
            _vm._v(_vm._s(_vm.formData.value)),
          ]),
          _c("info-row", { attrs: { label: "是否评审" } }, [
            _vm._v(_vm._s(_vm.formData.autoGenerate ? "否" : "是")),
          ]),
          _c("info-row", { attrs: { label: "评审人员" } }, [
            _vm._v(_vm._s(_vm.formData.creatorName)),
          ]),
          _c("info-row", { attrs: { label: "状态" } }, [
            _vm._v(_vm._s(this.getStatusText(_vm.formData))),
          ]),
          _c(
            "info-row",
            { attrs: { label: "历史记录" } },
            [
              _c(
                "a-timeline",
                { staticStyle: { "margin-top": "6px", width: "140%" } },
                _vm._l(_vm.logList, function (item, index) {
                  return _c(
                    "a-timeline-item",
                    { key: index, staticStyle: { "font-size": "14px" } },
                    [
                      _vm._v(
                        "(" +
                          _vm._s(item.gmtCreate) +
                          ")" +
                          _vm._s(" ") +
                          " " +
                          _vm._s(item.message)
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm.formData.status != 4
            ? _c(
                "div",
                { staticStyle: { "text-align": "right" } },
                [
                  _c(
                    "a-button",
                    {
                      directives: [
                        {
                          name: "has",
                          rawName: "v-has",
                          value: "statistics:review:reject",
                          expression: "'statistics:review:reject'",
                        },
                      ],
                      staticClass: "mr-3",
                      attrs: { type: "danger" },
                      on: { click: _vm.rejectRating },
                    },
                    [_vm._v("驳回")]
                  ),
                  _c(
                    "a-button",
                    {
                      directives: [
                        {
                          name: "has",
                          rawName: "v-has",
                          value: "statistics:review:confirm",
                          expression: "'statistics:review:confirm'",
                        },
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.confirmRating },
                    },
                    [_vm._v("确认")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "form" }, slot: "form" },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "dataForm",
                attrs: {
                  layout: "horizontal",
                  model: _vm.formData,
                  rules: _vm.rules,
                },
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "平台名称" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入平台名称", disabled: true },
                    model: {
                      value: _vm.formData.platformName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "platformName", $$v)
                      },
                      expression: "formData.platformName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "作品序号" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入作品序号", disabled: true },
                    model: {
                      value: _vm.formData.productOrder,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "productOrder", $$v)
                      },
                      expression: "formData.productOrder",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "作品名称" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入作品名称", disabled: true },
                    model: {
                      value: _vm.formData.productName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "productName", $$v)
                      },
                      expression: "formData.productName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "系数分类" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { disabled: true },
                      model: {
                        value: _vm.formData.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "type", $$v)
                        },
                        expression: "formData.type",
                      },
                    },
                    [
                      _c(
                        "a-select-option",
                        { attrs: { value: "TRANSLATOR" } },
                        [_vm._v("翻译")]
                      ),
                      _c("a-select-option", { attrs: { value: "BUILDER" } }, [
                        _vm._v("制作"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "难度系数" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入难度系数" },
                    model: {
                      value: _vm.formData.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "value", $$v)
                      },
                      expression: "formData.value",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "评审人员" } },
                [
                  _c("a-input", {
                    attrs: { disabled: true, placeholder: "请输入评审人员" },
                    model: {
                      value: _vm.formData.creatorName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "creatorName", $$v)
                      },
                      expression: "formData.creatorName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "状态" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { disabled: true },
                      model: {
                        value: _vm.formData.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "status", $$v)
                        },
                        expression: "formData.status",
                      },
                    },
                    [
                      _c(
                        "a-select-option",
                        { attrs: { value: "REVIEW_FINISH" } },
                        [_vm._v("BOSS确认")]
                      ),
                      _c(
                        "a-select-option",
                        { attrs: { value: "REVIEW_CONFIRM" } },
                        [_vm._v("HR确认")]
                      ),
                      _c(
                        "a-select-option",
                        { attrs: { value: "REVIEW_REJECT" } },
                        [_vm._v("BOSS驳回")]
                      ),
                      _c(
                        "a-select-option",
                        { attrs: { value: "REVIEW_SUBMIT" } },
                        [_vm._v("人员确认")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showScoreDetail
        ? _c(
            "div",
            [
              _c("g-title", [_vm._v("评分明细")]),
              _c("a-table", {
                ref: "table",
                attrs: {
                  size: "middle",
                  rowKey: "id",
                  columns: _vm.columns,
                  dataSource: _vm.taskList,
                  pagination: false,
                  loading: _vm.loading,
                  scroll: { x: 800, y: 500 },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }